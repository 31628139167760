export default function Hero() {
  return (
    <section id="home" className="header-hero">
      <div className="header-hero-content">
        <div className="container has-text-centered">
          <h1 className="text-white">Spin Doctor Tennis</h1>
          <p className="text-white">
            The Path to Tennis Excellence Starts Here
          </p>
        </div>
      </div>
    </section>
  );
}
