import "./Home.css";
import Hero from "../components/Hero";
import Layout from "../components/layout/Layout";
import { Link, Box, Container, Heading, Text } from "@chakra-ui/react";
import LessonsCards from "../components/LessonsCards";

export default function Home() {
  return (
    <Layout>
      <Hero />
      <Container maxW="2xl" centerContent my={12}>
        <Box textAlign="center">
          <Heading as="h2" fontSize="3xl" fontWeight="semibold" mb={4}>
            Specialized Tennis
          </Heading>
          <Text fontSize="lg" mb={6}>
            Spin Doctor Tennis offers a comprehensive selection of tennis
            programs designed for players of all ages, from children to adults.
            We tailor each of our camps to the unique needs of our participants.
            Enjoy year-round programs in Helsinki at the{" "}
            <Link
              variant="underline"
              href="https://www.google.com/maps/dir/60.15107,24.6529199/tali+tennishalli/@60.1863934,24.6094254,35227m/data=!3m2!1e3!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x468df61b7256b449:0x7c70ff2dcad9d9d8!2m2!1d24.8759404!2d60.2130427?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
            >
              Tennis Center Tali.
            </Link>
          </Text>
          <Text fontSize="lg">
            Enroll in a group class or schedule a private lesson today and
            discover what sets us apart.
          </Text>
        </Box>
      </Container>
      <LessonsCards />
    </Layout>
  );
}
