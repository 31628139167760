import Layout from "../components/layout/Layout";

const VideoDetails = () => {
  const videos = [
    {
      src: "https://www.youtube.com/embed/1VpVc9vqkAk",
      title: "Learning The “Circular Swing Pattern” for the Spin Serve",
    },
    {
      src: "https://www.youtube.com/embed/ZSzWKlj34M0",
      title: "The Flat Serve Unlocked",
    },
    {
      src: "https://www.youtube.com/embed/-YbhgJh1zGE",
      title: "Serve Tips with Alain 🎾: The Ball Toss",
    },
    {
      src: "https://www.youtube.com/embed/Pc3aoTEWV8w",
      title: "Serve Tips with Alain: The Spin Serve",
    },
    {
      src: "https://www.youtube.com/embed/dj-8y8WJd_s",
      title: "First Serve aka The Flat Serve",
    },
    {
      src: "https://www.youtube.com/embed/yjZwXWEXGws",
      title: "Second Serve Demonstration",
    },
  ];
  return (
    <section id="videos" className="section">
      <div className="container">
        <h1 className="title">Tennis Videos</h1>
        <div className="columns is-mobile is-multiline is-centered is-desktop">
          {videos.map((video) => {
            return (
              <div className="column">
                <div className="media">
                  <div className="media-content has-text-centered">
                    <iframe
                      width="560"
                      height="315"
                      src={video.src}
                      title={video.title}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

const Videos = () => {
  return (
    <Layout>
      <VideoDetails />
    </Layout>
  );
};

export default Videos;
