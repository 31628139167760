import {
  chakra,
  Stack,
  Box,
  Flex,
  Image,
  Button,
  Text,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import clinics from "../assets/clinics.webp";
// import groupLessons from "../assets/group-lessons.jpg";
import privateLessons from "../assets/private-lessons.jpg";
import { useNavigate } from "react-router-dom";

interface LessonCardProps {
  title: string;
  summary: string[];
  imageSrc: string;
  imageAlt: string;
  actionOnClickPath: string;
  actionTitle: string;
}
const LessonCard = ({
  title,
  summary,
  imageSrc,
  imageAlt,
  actionOnClickPath,
  actionTitle,
}: LessonCardProps) => {
  const navigate = useNavigate();

  return (
    <Box
      borderWidth="1px"
      maxW={"420px"}
      w={"full"}
      overflow={"hidden"}
      bg="white"
      border={"1px"}
      borderColor="black"
      boxShadow={useColorModeValue("6px 6px 0 #25D366", "6px 6px 0 #25D366")}
    >
      <Image
        src={imageSrc}
        alt={imageAlt}
        objectFit="cover"
        h={{ base: "250", md: "320" }}
      />
      <Stack p={6}>
        <Heading as="h2" size="lg" mb={4}>
          {title}
        </Heading>
        <ul>
          {summary.map((el, index) => (
            <li key={index}>{el}</li>
          ))}
        </ul>
        <Button
          colorScheme="messenger"
          variant="solid"
          onClick={() => {
            navigate(actionOnClickPath, { state: { targetId: "learn-more" } });
          }}
        >
          {actionTitle}
        </Button>
      </Stack>
    </Box>
  );
};

interface Props {
  showHeader?: boolean | undefined;
}

const LessonsCards = ({ showHeader }: Props) => {
  return (
    <div className="container">
      {showHeader && (
        <chakra.h1
          textAlign={"center"}
          fontSize={"4xl"}
          pt={8}
          fontWeight={"bold"}
        >
          Start Your Tennis Journey Here
        </chakra.h1>
      )}
      <Flex
        justify="center"
        wrap="wrap"
        gap={4}
        p={6}
        direction={{ base: "column", md: "row" }}
      >
        {/* Private Lessons Card */}
        <LessonCard
          title="Private Lessons"
          summary={[
            "• Personalized coaching for all skill levels",
            "• Flexible scheduling to suit you",
            "• Quickly improve with focused guidance",
          ]}
          imageAlt="Private Lessons"
          imageSrc={privateLessons}
          actionOnClickPath="/courses"
          actionTitle="Join now"
        />

        {/* Group Lessons Card */}
        {/*<Box
          flex={["1 1 auto", "1"]}
          borderWidth="1px"
          overflow="hidden"
          boxShadow="md"
          bg="white"
        >
          <Image
            src={groupLessons}
            h={{ base: "250", md: "320" }}
            alt="Group Lessons"
            objectFit="cover"
          />
          <Box p={6}>
            <Heading as="h2" size="lg" mb={4}>
              Group Lessons
            </Heading>
            <Text mb={6} color="gray.600">
              Join group tennis lessons in Helsinki, perfect for learning and
              growing together.
            </Text>
            <Button
              colorScheme="messenger"
              variant="solid"
              onClick={() => {
                navigate("/courses", { state: { targetId: "learn-more" } });
              }}
            >
              Join now
            </Button>
          </Box>
        </Box>*/}

        {/* Group Lessons Card */}
        <LessonCard
          title="Tennis Clinics"
          summary={[
            "• Targeted sessions for specific skills",
            "• Master serves, forehands, backhands, and more",
            "• Sharpen technique in a supportive setting",
          ]}
          imageAlt="Tennis Clinics"
          imageSrc={clinics}
          actionOnClickPath="/courses"
          actionTitle="Learn more"
        />
      </Flex>
    </div>
  );
};

export default LessonsCards;
