import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { About } from "./pages/About";
import Courses from "./pages/Courses";
import Videos from "./pages/Videos";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/coach" element={<About />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/videos" element={<Videos />} />
    </Routes>
  );
}

export default App;
