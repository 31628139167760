import {
  Box,
  chakra,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";

interface StatsCardProps {
  title: string;
  stat: string;
  bgColorHex: string;
}
function StatsCard(props: StatsCardProps) {
  const { title, stat, bgColorHex } = props;
  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py={"5"}
      shadow={"xl"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
      bg={bgColorHex}
    >
      <StatLabel fontSize={"4xl"} fontWeight={"bold"} isTruncated>
        {title}
      </StatLabel>
      <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
        {stat}
      </StatNumber>
    </Stat>
  );
}

export default function HowItWorks() {
  return (
    <div className="container" id="learn-more">
      <Box maxW="7xl" mx={"auto"} py={16}>
        <chakra.h1
          textAlign={"center"}
          fontSize={"4xl"}
          py={10}
          fontWeight={"bold"}
        >
          How it works?
        </chakra.h1>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
          <StatsCard
            bgColorHex="#EAF2F4"
            title={"Find the course"}
            stat={"which fits your need"}
          />
          <StatsCard
            bgColorHex="#AEDDE0"
            title={"Contact the coach"}
            stat={"on WhatsApp or Phone Call"}
          />
          <StatsCard
            bgColorHex="#F9F2D4"
            title={"Book & Confirm"}
            stat={"the lesson of your choice"}
          />
        </SimpleGrid>
      </Box>
    </div>
  );
}
