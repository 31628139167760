import "./About.css";
import alainPhoto from "../assets/alain-portrait.jpeg";
import Layout from "../components/layout/Layout";
import Hero from "../components/Hero";
import { Box, Flex, Grid, Image, Heading, Text, Link } from "@chakra-ui/react";

export const About = () => {
  return (
    <Layout>
      <Hero />
      <section id="coach">
        <Box my={12} maxW="5xl" mx="auto">
          <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={4}>
            <Box>
              <Heading as="h2" size="lg">
                About Coach
              </Heading>
              <Text mt={4} fontSize={"xl"}>
                Hello, I'm Alain. Thank you for visiting my website. My teaching
                style focuses on technique. Good technique will make playing
                tennis easier and more efficient. I attempt to maximize your
                style of play and give your game the overall balance it needs to
                compete. Every lesson is designed with your needs in mind with
                an emphasis on the foundations, technique, body mechanics and
                game strategy. I have been a certified instructor with{" "}
                <Link href="https://ptrtennis.org/">
                  Professional Tennis Registry (PTR)
                </Link>{" "}
                since 2000.
              </Text>
              <br />
              <Text mt={4} fontSize={"xl"}>
                I have been teaching tennis since 1980. My coaching style has
                evolved quite a bit since then. I am always open to new
                information because tennis is constantly changing. I often do
                presentations for Tennis Hedman coaches on techniques, and
                strategies on how to teach tennis more effectively. Check out my{" "}
                <Link
                  href="https://www.youtube.com/playlist?list=PLxFP6IGkuwecO6iS23mdYzxpbUvPUxbmj"
                  isExternal
                >
                  <strong>YouTube channel</strong>
                </Link>{" "}
                for bite-sized lessons and tennis advice. Don't forget to
                subscribe!
              </Text>
            </Box>
            <Box>
              <Image
                src={alainPhoto}
                alt="About Coach"
                boxSize="500px"
                objectFit="cover"
              />
            </Box>
          </Grid>
        </Box>
      </section>
    </Layout>
  );
};
