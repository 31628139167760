import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Flex,
  Box,
  Center,
  Divider,
  Heading,
  Link,
  Spacer,
  HStack,
  VStack,
  Text,
  Container,
} from "@chakra-ui/react";

import iconWhatsApp from "../../assets/whatsapp.png";
import iconEmail from "../../assets/mail.png";
import iconMap from "../../assets/map.png";

function FooterElement({ icon, content }: { icon: string; content: string }) {
  return (
    <HStack p={2}>
      <span className="icon-text">
        <span className="icon">
          <img src={icon} alt="" />
        </span>
        <span>&nbsp;&nbsp;{content}</span>
      </span>
    </HStack>
  );
}

export default function Footer() {
  return (
    <section id="contact" className="hero">
      <Box
        background={"gray.100"}
        borderTopRadius="2xl"
        borderColor={"gray.200"}
        borderWidth={1}
      >
        <Container minWidth="max-content">
          <Flex gap={2} p={4} wrap={"wrap"} mt={8} direction={"row"}>
            <VStack align={"flex-start"} w={{ base: "full", md: "350px" }}>
              <Heading size={"lg"}>Contact</Heading>
              <FooterElement icon={iconWhatsApp} content="040 733 9282" />
              <FooterElement
                icon={iconEmail}
                content="alainbrouillaud@mac.com"
              />
              <FooterElement
                icon={iconMap}
                content="Talin Tenniskeskus, Helsinki"
              />
            </VStack>
            <Spacer />
            <VStack
              align={"flex-start"}
              p={2}
              w={{ base: "full", md: "350px" }}
            >
              <Heading size={"lg"}>Social Media</Heading>
              <Link
                href="https://www.youtube.com/playlist?list=PLxFP6IGkuwecO6iS23mdYzxpbUvPUxbmj"
                isExternal
              >
                Facebook <ExternalLinkIcon mx="2px" />
              </Link>
              <Link
                href="https://www.youtube.com/playlist?list=PLxFP6IGkuwecO6iS23mdYzxpbUvPUxbmj"
                isExternal
              >
                Youtube <ExternalLinkIcon mx="2px" />
              </Link>
            </VStack>
            <Spacer />
            <VStack
              align={"flex-start"}
              p={2}
              w={{ base: "full", md: "350px" }}
            >
              <Heading size={"lg"}>Links</Heading>
              <Link href="/terms-of-service.html" isExternal>
                Terms of Service <ExternalLinkIcon mx="2px" />
              </Link>
              {/*<Link href="https://www.sendowl.com/" isExternal>
                Powered by SendOwl <ExternalLinkIcon mx="2px" />
  </Link>*/}
              <Link
                href="https://www.tennishedman.fi/en/tennis-hedman/#valmentajamme"
                isExternal
              >
                Tennis Hedman
                <ExternalLinkIcon mx="2px" />
              </Link>
            </VStack>
          </Flex>
          <Box alignContent={"center"} alignItems={"center"} pb={8}>
            <Center>
              <VStack w={"full"} px={4}>
                <Divider
                  orientation="horizontal"
                  height={"0.5px"}
                  bg={"blackAlpha.400"}
                />
                <Text align={"center"}>
                  www.spindoctortennis.com | Alain Brouillaud | Professional
                  Tennis Coach
                </Text>
                <Text fontSize={"sm"} align={"center"}>
                  © 2024 All rights reserved. | Site design by Maninder Singh
                </Text>
              </VStack>
            </Center>
          </Box>
        </Container>
      </Box>
    </section>
  );
}
