import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";

import {
  Button,
  Heading,
  Text,
  Badge,
  Box,
  chakra,
  Flex,
  Tooltip,
  useColorModeValue,
  Link,
  UnorderedList,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Hero from "../components/Hero";
import LessonsCards from "../components/LessonsCards";
import { useLocation } from "react-router-dom";
import HowItWorks from "../components/HowItWorks";

type Event = {
  name: string;
  desc: string | undefined;
  link: string;
  date: string | undefined;
  month: string;
  spotMessage: string | undefined;
  isBookable: boolean;
};

type Clinic = {
  name: string;
  link: string;
};

const returnOfServeFocus: Clinic = {
  name: "Return of Serve Focus",
  link: "https://transactions.sendowl.com/products/79210796/836BA994/view",
};

const forehandFocus: Clinic = {
  name: "Forehand Focus",
  link: "https://transactions.sendowl.com/products/79215704/74768652/view",
};

const flatServeClinic: Clinic = {
  name: "Flat Serve Focus",
  link: "https://transactions.sendowl.com/products/79215716/96F0B3E7/view",
};

const spinServeClinic: Clinic = {
  name: "Spin Serve Focus",
  link: "https://transactions.sendowl.com/products/79215718/366A9176/view",
};

const privateLessons: Clinic = {
  name: "Private Tennis Lesson - 1 hour",
  link: "https://transactions.sendowl.com/products/79229583/B383F5F1/view",
};

const events: Event[] = [
  /*{
    date: undefined,
    name: privateLessons.name,
    desc: "Personalized Coaching to Improve Your Skills and Technique",
    link: privateLessons.link,
    month: "",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: undefined,
    name: "Group Lessons",
    desc: "Join group tennis lessons in Helsinki, perfect for learning and growing together!",
    link: privateLessons.link,
    month: "",
    spotMessage: undefined,
    isBookable: true,
  },*/
  {
    date: "11.01",
    name: flatServeClinic.name,
    desc: undefined,
    link: flatServeClinic.link,
    month: "January 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "18.01",
    name: spinServeClinic.name,
    desc: undefined,
    link: spinServeClinic.link,
    month: "January 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "25.01",
    name: forehandFocus.name,
    desc: undefined,
    link: forehandFocus.link,
    month: "January 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "01.02",
    name: returnOfServeFocus.name,
    desc: undefined,
    link: returnOfServeFocus.link,
    month: "February 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "08.02",
    name: flatServeClinic.name,
    desc: undefined,
    link: flatServeClinic.link,
    month: "February 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "15.02",
    name: spinServeClinic.name,
    desc: undefined,
    link: spinServeClinic.link,
    month: "February 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "22.02",
    name: forehandFocus.name,
    desc: undefined,
    link: forehandFocus.link,
    month: "February 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "01.03",
    name: returnOfServeFocus.name,
    desc: undefined,
    link: returnOfServeFocus.link,
    month: "March 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "29.03",
    name: flatServeClinic.name,
    desc: undefined,
    link: flatServeClinic.link,
    month: "March 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "05.04",
    name: spinServeClinic.name,
    desc: undefined,
    link: spinServeClinic.link,
    month: "April 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "12.04",
    name: forehandFocus.name,
    desc: undefined,
    link: forehandFocus.link,
    month: "April 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "19.04",
    name: returnOfServeFocus.name,
    desc: undefined,
    link: returnOfServeFocus.link,
    month: "April 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "26.03",
    name: flatServeClinic.name,
    desc: undefined,
    link: flatServeClinic.link,
    month: "April 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "03.05",
    name: spinServeClinic.name,
    desc: undefined,
    link: spinServeClinic.link,
    month: "May 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "10.05",
    name: forehandFocus.name,
    desc: undefined,
    link: forehandFocus.link,
    month: "May 2025",
    spotMessage: undefined,
    isBookable: true,
  },
  {
    date: "17.05",
    name: returnOfServeFocus.name,
    desc: undefined,
    link: returnOfServeFocus.link,
    month: "May 2025",
    spotMessage: undefined,
    isBookable: true,
  },
];

type ClinicItemProps = {
  event: Event;
};

// Define the type for groupedEvents
type GroupedEvents = {
  [key: string]: Event[];
};

const ClinicItem = ({ event }: ClinicItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = (
    eventName: string | undefined,
    eventDate: string | undefined
  ) => {
    setIsOpen(false);

    if (!eventName || !eventDate) {
      return;
    }

    // Function to detect if the user is on a mobile browser
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const message =
      "Hello Alain, I would like to confirm my booking for the tennis clinic - " +
      eventName +
      " | " +
      eventDate;
    const phoneNumber = "358407339282";

    // Create a URL dynamically with encoding
    const mobileUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    const desktopUrl = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    const redirectUrl = isMobile ? mobileUrl : desktopUrl;

    // Redirect to the WhatsApp URL
    window.location.href = redirectUrl;
  };

  return (
    <Box
      bg={event.isBookable ? "white" : "#e5e5e5"}
      borderWidth="1px"
      rounded="lg"
      shadow="lg"
      position="relative"
    >
      <Box p={{ base: "4" }} minW={{ base: "280", md: "340" }}>
        <Box display="flex" alignItems="baseline">
          {event.spotMessage && (
            <Badge
              rounded="full"
              px="4"
              py={2}
              fontSize="0.8em"
              colorScheme="red"
            >
              {event.spotMessage}
            </Badge>
          )}
          {event.isBookable && !event.spotMessage && (
            <Badge
              rounded="full"
              px="4"
              py={2}
              fontSize="0.8em"
              colorScheme="green"
            >
              Max 3 players
            </Badge>
          )}
        </Box>
        <Flex mt="1" justifyContent="space-between" alignContent="center">
          <Box
            fontSize="2xl"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {event.name}
          </Box>

          <Tooltip
            label="Add to cart"
            bg="white"
            placement={"top"}
            color={"gray.800"}
            fontSize={"1.2em"}
          >
            <chakra.a href={"#"} display={"flex"}></chakra.a>
          </Tooltip>
        </Flex>
        {event.desc && (
          <Box fontSize="lg" fontWeight="normal" isTruncated>
            {event.desc}
          </Box>
        )}
        <Flex justifyContent="space-between" alignContent="center">
          <Box fontSize="xl" color={useColorModeValue("gray.800", "white")}>
            <Box as="span" color={"gray.600"} fontSize="lg">
              📅 &nbsp;&nbsp;&nbsp;
            </Box>
            {event.date || "On mutual agreement"}
          </Box>
        </Flex>
        {!event.isBookable && <Text fontSize={"lg"}>No spot left</Text>}
        {event.isBookable && (
          <Button mt={4} colorScheme="teal" onClick={() => setIsOpen(true)}>
            Book Now
          </Button>
        )}
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose(undefined, undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Booking Instructions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              Please follow the instructions below to confirm your booking:
            </Text>
            <UnorderedList>
              <ListItem>
                Contact Coach via Phone (<strong>+358 407 339 282</strong>) -
                WhatsApp, phone call, or over Email (
                <strong>alainbrouillaud@mac.com</strong>) to confirm your
                booking.
              </ListItem>
              <ListItem>
                Spots are limited, so book early to secure your place.
              </ListItem>
              <ListItem>
                Each tennis clinic accommodates a maximum of 3 players.
              </ListItem>
            </UnorderedList>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                onClose(event.name, event.date);
              }}
            >
              WhatsApp Alain
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const CourseDetails = () => {
  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);

  // Get the current date
  const today = new Date();
  const currentYear = today.getFullYear();

  // Filter events
  const upcomingEvents = events.map((event) => {
    if (!event.date) return event; // Keep events without a date

    // Parse the event date
    const [day, month] = event.date.split(".").map(Number);
    const eventDate = new Date(currentYear, month - 1, day); // Months are 0-based

    // Check if the event date is in the future
    if (event.isBookable && eventDate < today) {
      const newEvent: Event = {
        ...event,
        isBookable: false,
      };
      return newEvent;
    }
    return event;
  });

  // Group events by month
  const groupedEvents: GroupedEvents = upcomingEvents.reduce(
    (acc: GroupedEvents, event: Event) => {
      const { month } = event;
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(event);
      return acc;
    },
    {}
  );

  const colors = [
    "purple.50",
    "orange.50",
    "teal.50",
    "pink.50",
    "blue.50",
    "yellow.50",
    "cyan.50",
  ];

  return (
    <div className="container">
      <h1 className="title">Tennis Clinics/Lessons</h1>
      <Text fontSize={"xl"} px={{ base: 4, md: 0 }}>
        The Spring season kick starts with clinics starting on{" "}
        <strong>January 11, 2025</strong>. Key information about clinics:
      </Text>
      {/**<ul>
          <li>
            <Text fontSize={"lg"}>
              · &nbsp;&nbsp; The clinics are adapted to the individual level and
              individual need of the participants.
            </Text>
          </li>
          <li>
            <Text fontSize={"lg"}>
              · &nbsp;&nbsp; Note: All clinics are organized in
              <strong>Tali Tenniskeskus, Helsinki</strong>,{" "}
              <strong>Court #6</strong>, <strong>03:00-04:00 PM</strong>
            </Text>
          </li>
        </ul> */}
      <Box px={6}>
        <Text>
          Each tennis focus group will teach player have a better idea on how to
          execute the shot in focus.{" "}
        </Text>
        <UnorderedList>
          <ListItem>
            <strong>Introduction</strong> - What the goal of the focus group for
            the hour
          </ListItem>
          <ListItem>
            <strong>Quick analysis</strong> - How each player is hitting the
            shot… and how to fix it
          </ListItem>
          <ListItem>
            <strong>Implementation</strong> - Each player will hit well over 100
            shots of the particular stroke being focused on
          </ListItem>
          <ListItem>
            <strong>The Finish</strong> - Each player will:
            <UnorderedList>
              <ListItem>Have a better understanding of the shot</ListItem>
              <ListItem>
                Execute the shot better than when he/she started the focus
                clinic
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
      </Box>
      <Box bg={"green.50"} p={2} rounded={16} fontSize={"lg"} mt={4}>
        Optional - Video analysis available upon request
      </Box>
      <Box bg={"blue.50"} p={4} rounded={8} fontSize={"xl"} mt={4}>
        👉 &nbsp;&nbsp; For any other queries or questions, feel free to reach
        out on via email at <strong>alainbrouillaud@mac.com </strong>or give a
        call/whatsapp <strong>040 733 9282</strong>
      </Box>
      <Box bg={"red.50"} p={4} rounded={8} fontSize={"xl"} mt={4}>
        👉 &nbsp;&nbsp; For <strong>MobilePay</strong> payment, reach out to
        Alain by call/whatsapp <strong>040 733 9282</strong>
      </Box>
      {Object.keys(groupedEvents).map((month, index) => (
        <Box
          key={month}
          bg={colors[index]}
          p={{ base: "2", md: "4" }}
          rounded={8}
          my={{ base: "4", md: "4" }}
        >
          {month && (
            <Heading as="h4" fontSize={"2xl"} mt={4} mx={4}>
              {month}
            </Heading>
          )}
          <Flex
            gap="4"
            alignItems={"flex-start"}
            wrap={"wrap"}
            p={4}
            direction={"row"}
            rounded={12}
          >
            {groupedEvents[month].map((event, index) => (
              <ClinicItem key={index} event={event} />
            ))}
          </Flex>
        </Box>
      ))}
    </div>
  );
};

const Courses = () => {
  return (
    <Layout>
      <Hero />
      <LessonsCards showHeader />
      <HowItWorks />
      <CourseDetails />
    </Layout>
  );
};

export default Courses;
