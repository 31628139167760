import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Spacer,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

interface Props {
  link: string;
  children: React.ReactNode;
}

type LinkItem = {
  link: string;
  label: string;
};

// "Home", "About", "Clinics", "Videos", "Reviews"
const Links: LinkItem[] = [
  {
    link: "/",
    label: "Home",
  },
  {
    link: "/coach",
    label: "Coach",
  },
  {
    link: "/courses",
    label: "Courses",
  },
  {
    link: "/videos",
    label: "Videos",
  },
  {
    link: "#reviews",
    label: "Reviews",
  },
];

const NavLink = (props: Props) => {
  const { link, children } = props;

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("blue.50", "blue.500"),
      }}
      fontSize={"xl"}
      fontWeight={"semibold"}
      href={link}
    >
      {children}
    </Box>
  );
};

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box px={4}>
      <Flex
        minWidth="max-content"
        alignItems="center"
        justifyContent={"space-between"}
        h={{ base: "none", md: "16" }}
      >
        <Spacer />
        <HStack spacing={8} alignItems={"center"}>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {Links.map((item) => (
              <NavLink key={item.link} link={item.link}>
                {item.label}
              </NavLink>
            ))}
          </HStack>
        </HStack>
        <Spacer />
      </Flex>

      <IconButton
        size={"md"}
        my={2}
        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        aria-label={"Open Menu"}
        display={{ md: "none" }}
        onClick={isOpen ? onClose : onOpen}
      />

      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {Links.map((item) => (
              <NavLink key={item.link} link={item.link}>
                {item.label}
              </NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default Header;
